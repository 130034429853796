import React from "react";
import ReactDOM from "react-dom/client";
import "./theme.scss";
import "./i18n";
import App from "./App";
import { Helmet } from "react-helmet";
import {
  manifestUrl,
  favIconUrl,
  metaTitle,
  metaDescription
} from "./utils/constants";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Helmet>
      <link rel="icon" href={`${process.env.PUBLIC_URL}/${favIconUrl}`} />
      <meta name="description" content={metaDescription} />
      <title>{metaTitle}</title>
      <link
        rel="apple-touch-icon"
        href={`${process.env.PUBLIC_URL}/${favIconUrl}`}
      />
      <link rel="manifest" href={`${process.env.PUBLIC_URL}/${manifestUrl}`} />
    </Helmet>
    <App />
  </React.StrictMode>
);
