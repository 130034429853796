import { useTranslation } from "react-i18next";
import "./styles.scss";

const NotFoundPage = () => {
  const { t, i18n } = useTranslation("common");

  return (
    <div id="not-found-page" data-testid="not-found-page">
      <h1>404</h1>
      <h2>{t("pageNotFonud")}</h2>
      <a href={`/${i18n.language}`}>{t("returnToHome")}</a>
    </div>
  );
};

export default NotFoundPage;
