import { useEffect } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { defaultLanguage } from "../../utils/constants";

const LanguageDetector = () => {
  const location = useLocation();
  const { language } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!language) {
      navigate(`/${defaultLanguage}${location.pathname}`);
    }
  }, [location, language, navigate]);

  if (!language) {
    return null;
  }

  return <Outlet />;
};

export default LanguageDetector;
