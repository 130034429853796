import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { isFacet } from "../../utils/constants";

const Header = ({ displayHomeBtn }: { displayHomeBtn?: boolean }) => {
  const [scrolled, setScrolled] = useState(false);
  const { t, i18n } = useTranslation("common");
  const navigate = useNavigate();
  const location = useLocation();
  const { language } = useParams();

  const onChangeLanguage = () => {
    if (isFacet) {
      const pathNameArr = location.pathname.split("/");
      pathNameArr[1] = language === "en" ? "fr" : "en";
      navigate(pathNameArr.join("/"));
      i18n.changeLanguage(pathNameArr[1]);
    } else {
      if (language === "en" && !!process.env.REACT_APP_OTIP_FRENCH_URL) {
        // to french
        window.location.href = process.env.REACT_APP_OTIP_FRENCH_URL;
      } else if (
        language === "fr" &&
        !!process.env.REACT_APP_OTIP_ENGLISH_URL
      ) {
        // to english
        window.location.href = process.env.REACT_APP_OTIP_ENGLISH_URL;
      }
    }
  };

  useEffect(() => {
    const updateScrolled = () => {
      setScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", updateScrolled);

    return () => window.removeEventListener("scroll", updateScrolled);
  }, []);

  const getLogoSrc = () => {
    if (isFacet) {
      return `${process.env.PUBLIC_URL}/static/facet/logo.png`;
    } else {
      return `${process.env.PUBLIC_URL}/static/otip/logo.png`;
    }
  };

  return (
    <header id="header" className={scrolled ? "scrolled" : ""}>
      <div id="header-content">
        <div id="logo-wrapper" className={isFacet ? "facet" : "otip"}>
          <a href={`/${language}`}>
            <img src={getLogoSrc()} alt="logo" />
          </a>
        </div>

        <div id="menu">
          <div>
            <nav>
              {displayHomeBtn ? (
                <div id="home-btn-wrapper">
                  <a
                    href={`/${i18n.language}`}
                    className={isFacet ? "facet" : "otip"}
                  >
                    {t("home")}
                  </a>
                </div>
              ) : null}
              <button
                id="language-toggle"
                className={isFacet ? "facet" : "otip"}
                onClick={onChangeLanguage}
              >
                {language === "en" ? "Fr" : "En"}
              </button>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
