export const defaultLanguage = process.env.REACT_APP_NAME?.includes("_FR")
  ? "fr"
  : "en";

export const isFacet = process.env.REACT_APP_NAME?.includes("FACET_APPEAL");

export const metaTitle =
  process.env.REACT_APP_META_TITLE || "FACET Appeals Process";
export const metaDescription =
  process.env.REACT_APP_META_DESCRIPTION || "FACET";
export const favIconUrl = process.env.REACT_APP_FAVICON_URL || "";
export const manifestUrl = process.env.REACT_APP_MANIFEST || "";
