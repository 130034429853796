import { useTranslation } from "react-i18next";
import "./background.scss";
import "./content.scss";
import { isFacet } from "../../utils/constants";

const AppealProcess = () => {
  const { t, i18n } = useTranslation("home");

  const EN_FORM = `${process.env.PUBLIC_URL}/static/${
    isFacet ? "facet" : "otip"
  }/prior_authorization_appeal_form.pdf`;
  const FR_FORM = `${process.env.PUBLIC_URL}/static/${
    isFacet ? "facet" : "otip"
  }/prior_authorization_appeal_form_fr.pdf`;

  return (
    <main>
      <div id="background">
        <div id="main-content-wrapper">
          <div id="main-content">
            <h1 className={isFacet ? "facet" : "otip"}>
              {isFacet ? t("facetTitle") : t("title")}
            </h1>
            <div id="content-wrapper">
              <div id="content">
                {t("objective")}
                <br />
                <br />
                {t("review")}
                <br />
                <br />
                <b>{t("subtitle")}</b>
                <br />
                <br />
                <table
                  style={{
                    verticalAlign: "middle",
                    textAlign: "left"
                  }}
                >
                  <tbody>
                    <tr>
                      <td style={{ width: "50px", height: "50px" }}>
                        <img
                          src={`${process.env.PUBLIC_URL}/static/${
                            isFacet ? "facet" : "otip"
                          }/step-1.png`}
                          alt="Step one"
                          width="35"
                          height="35"
                        />
                      </td>
                      <td>{t("step1")}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50px", height: "50px" }}>
                        <img
                          src={`${process.env.PUBLIC_URL}/static/${
                            isFacet ? "facet" : "otip"
                          }/step-2.png`}
                          alt="Step two"
                          width="35"
                          height="35"
                        />
                      </td>
                      <td>{t("step2")}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50px", height: "50px" }}>
                        <img
                          src={`${process.env.PUBLIC_URL}/static/${
                            isFacet ? "facet" : "otip"
                          }/step-3.png`}
                          alt="Step three"
                          width="35"
                          height="35"
                        />
                      </td>
                      <td>{t("step3")}</td>
                    </tr>
                  </tbody>
                </table>
                <br />
              </div>
            </div>
            <div id="button-group" className={isFacet ? "facet" : "otip"}>
              <a
                id="download-button"
                className="button"
                download
                href={i18n.language === "fr" ? FR_FORM : EN_FORM}
              >
                {t("download")}
              </a>
              <a href="#learnMore" className="button">
                {isFacet ? t("facetLearnMore") : t("learnMore")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AppealProcess;
