import AppealProcess from "../../components/appeal-process/AppealProcess";
import FAQs from "../../components/faqs/FAQs";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Process from "../../components/process/Process";

const HomePage = () => {
  return (
    <div id="home-page">
      <Header />
      <AppealProcess />
      <div id="learnMore"></div>
      <FAQs />
      <Process />
      <Footer displayDownloadBtn={true} />
    </div>
  );
};

export default HomePage;
