import { useTranslation } from "react-i18next";
import "./styles.scss";
import { isFacet } from "../../utils/constants";

const Process = () => {
  const { t } = useTranslation("process");

  return (
    <div id="process">
      <h2 className={isFacet ? "facet" : "otip"}>{t("title")}</h2>
      <div id="list-wrapper">
        <div className="list">
          <div className="img-wrapper">
            <img
              src={`${process.env.PUBLIC_URL}/static/${
                isFacet ? "facet" : "otip"
              }/process-step-1.svg`}
              alt="Step - 1 Icon"
            />
          </div>
          <div className="description-wrapper">
            <h5 className={isFacet ? "facet" : "otip"}>{t("step1")}</h5>
            <p>{t("step1Content")}</p>
          </div>
        </div>

        <div className="list">
          <div className="img-wrapper">
            <img
              src={`${process.env.PUBLIC_URL}/static/${
                isFacet ? "facet" : "otip"
              }/process-step-2.svg`}
              alt="Step - 2 Icon"
            />
          </div>
          <div className="description-wrapper">
            <h5 className={isFacet ? "facet" : "otip"}>{t("step2")}</h5>
            <p>{t("step2Content")}</p>
          </div>
        </div>

        <div className="list">
          <div className="img-wrapper">
            <img
              src={`${process.env.PUBLIC_URL}/static/${
                isFacet ? "facet" : "otip"
              }/process-step-3.svg`}
              alt="Step - 3 Icon"
            />
          </div>
          <div className="description-wrapper">
            <h5 className={isFacet ? "facet" : "otip"}>{t("step3")}</h5>
            <p>{t("step3Content")}</p>
          </div>
        </div>

        <div className="list">
          <div className="img-wrapper">
            <img
              src={`${process.env.PUBLIC_URL}/static/${
                isFacet ? "facet" : "otip"
              }/process-step-4.svg`}
              alt="Step - 4 Icon"
            />
          </div>
          <div className="description-wrapper">
            <h5 className={isFacet ? "facet" : "otip"}>{t("step4")}</h5>
            <p>{t("step4Content")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Process;
