import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from "react-router-dom";
import NotFoundPage from "./pages/not-found-page/NotFoundPage";
import HomePage from "./pages/home-page/HomePage";
import LanguageDetector from "./components/language-detector/LanguageDetector";
import PrivacyPage from "./pages/privacy-page/PrivacyPage";

const App = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route path="/" element={<LanguageDetector />} />
        <Route path="/:language" element={<LanguageDetector />}>
          <Route path="/:language/" index element={<HomePage />} />
          <Route path="/:language/privacy" index element={<PrivacyPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Route>
    )
  );
  return <RouterProvider router={router} />;
};

export default App;
