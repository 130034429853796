import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { styled } from "@mui/material";
import { useState } from "react";
import { isFacet } from "../../utils/constants";

interface StyledTabProps {
  label: string;
}

interface StyledTabsProps {
  children?: React.ReactNode;
  variant?: "fullWidth" | "standard" | "scrollable" | undefined;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const CustomizedTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(() => ({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    top: 0
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    backgroundColor: isFacet ? "#1B6F65" : "#D9A867"
  }
}));

const CustomizedTab = styled((props: StyledTabProps) => (
  <Tab {...props} id="tab" />
))(() => ({
  textTransform: "none",
  color: "black",
  fontSize: "18px",
  lineHeight: "30px",
  fontFamily: "'Open Sans',system-ui,sans-serif",
  fontWeight: 600,
  padding: "15px",
  "&.Mui-selected": {
    backgroundColor: "white",
    borderBottom: "none",
    color: "black"
  },
  "&.MuiTabs-indicator": {
    color: "red",
    backgroundColor: "red",
    width: "10px"
  }
}));

const FAQs = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const { t } = useTranslation("faq");

  return (
    <div id="faqs">
      <h2 className={isFacet ? "facet" : "otip"}>{t("title")}</h2>
      <div id="tab-wrapper">
        <header>
          <CustomizedTabs
            variant="fullWidth"
            value={selectedTab}
            onChange={(_, value) => setSelectedTab(value)}
          >
            <CustomizedTab label={t("process")}></CustomizedTab>
            <CustomizedTab label={t("outcome")}></CustomizedTab>
          </CustomizedTabs>
        </header>
        <div role="tabpanel" hidden={selectedTab === 1} id="tabpanel-0">
          <div className="tab-content-wrapper">
            <ul>
              <li>{t("processList1")}</li>
              <li>{t("processList2")}</li>
              <li>{t("processList3")}</li>
              <li>{t("processList4")}</li>
              <li>{t("processList5")}</li>
              <li>{t("processList6")}</li>
              <li>{t("processList7")}</li>
              <li>{t("processList8")}</li>
              <li>{t("processList9")}</li>
            </ul>
          </div>
        </div>
        <div role="tabpanel" hidden={selectedTab === 0} id="tabpanel-1">
          <div className="tab-content-wrapper">
            <ul>
              <li>{t("outcomeList1")}</li>
              <li>{t("outcomeList2")}</li>
              <li>{t("outcomeList3")}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQs;
