import { useTranslation } from "react-i18next";
import "./styles.scss";
import { useEffect, useState } from "react";
import { isFacet } from "../../utils/constants";

const Footer = ({ displayDownloadBtn }: { displayDownloadBtn: Boolean }) => {
  const { t, i18n } = useTranslation("common");
  const [hidden, setHidden] = useState<boolean>(true);

  const EN_FORM = `${process.env.PUBLIC_URL}/static/${
    isFacet ? "facet" : "otip"
  }/prior_authorization_appeal_form.pdf`;
  const FR_FORM = `${process.env.PUBLIC_URL}/static/${
    isFacet ? "facet" : "otip"
  }/prior_authorization_appeal_form_fr.pdf`;

  const getLogoSrc = () => {
    if (isFacet) {
      return `${process.env.PUBLIC_URL}/static/facet/logo.png`;
    } else {
      return `${process.env.PUBLIC_URL}/static/otip/logo.png`;
    }
  };

  useEffect(() => {
    const updateHidden = () => {
      setHidden(window.scrollY / window.innerHeight <= 0.5);
    };

    window.addEventListener("scroll", updateHidden);

    return () => window.removeEventListener("scroll", updateHidden);
  }, []);

  return (
    <div id="footer">
      <div id="footer-content-wrapper">
        <div id="footer-content">
          <div id="logo-wrapper" className={isFacet ? "facet" : "otip"}>
            <img src={getLogoSrc()} alt="logo" />
          </div>
          <a href={`/${i18n.language}/privacy`} id="privacy-link">
            {t("privacy")}
          </a>
        </div>
        {displayDownloadBtn ? (
          <div
            id="download-btn-wrapper"
            className={`${hidden ? "hidden" : ""} ${
              i18n.language === "fr" ? "margin" : ""
            }`}
          >
            <a
              id="download-btn"
              className={isFacet ? "facet" : "otip"}
              download
              href={i18n.language === "fr" ? FR_FORM : EN_FORM}
            >
              {t("download")}
            </a>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Footer;
