import { useTranslation } from "react-i18next";
import Header from "../../components/header/Header";
import "./styles.scss";
import Footer from "../../components/footer/Footer";
import { isFacet } from "../../utils/constants";

const PrivacyPage = () => {
  const { t } = useTranslation("privacy");

  return (
    <div id="privacy-page">
      <Header displayHomeBtn />
      <main>
        <div id="content-wrapper">
          <div id="content">
            <h1 className={isFacet ? "facet" : "otip"}>{t("title")}</h1>
            <div id="paragraph-wrapper">
              <p>{t("paragraph1")}</p>
              <p>{t("paragraph2")}</p>
              <p>
                {t("paragraph3")} <i>{t("paragraph3Document")}</i>.
              </p>
              <p>{t("paragraph4")}</p>
              <h3>{t("accountability")}</h3>
              <p>{t("accountabilityParagraph1")}</p>
              <h3>{t("identifyingPurposes")}</h3>
              <p>{t("identifyingPurposesParagraph1")}</p>
              <p>{t("identifyingPurposesParagraph2")}</p>
              <h3>{t("consent")}</h3>
              <p>{t("consentParagraph1")}</p>
              <p>{t("consentParagraph2")}</p>
              <ul>
                <li>{t("consentList1")}</li>
                <li>{t("consentList2")}</li>
                <li>{t("consentList3")}</li>
                <li>{t("consentList4")}</li>
                <li>{t("consentList5")}</li>
              </ul>
              <p>{t("consentParagraph3")}</p>
              <p>{t("consentParagraph4")}</p>
              <h3>{t("limitCollection")}</h3>
              <p>{t("limitCollectionParagraph1")}</p>
              <p>{t("limitCollectionParagraph2")}</p>
              <p>{t("limitCollectionParagraph3")}</p>
              <h3>{t("limitUse")}</h3>
              <p>{t("limitUseParagraph1")}</p>
              <p>{t("limitUseParagraph2")}</p>
              <p>{t("limitUseParagraph3")}</p>
              <p>{t("limitUseParagraph4")}</p>
              <h3>{t("accuracy")}</h3>
              <p>{t("accuracyParagraph")}</p>
              <h3>{t("safeguards")}</h3>
              <p>{t("safeguardsParagraph")}</p>
              <h3>{t("openness")}</h3>
              <p>{t("opennessParagraph")}</p>
              <h3>{t("individualAccess")}</h3>
              <p>{t("individualAccessParagraph")}</p>
              <h3>{t("inquiries")}</h3>
              <p>{t("inquiriesParagraph")}</p>
              <h4>{t("inquiriesSection1")}</h4>
              <p>{t("inquiriesSection1Paragraph1")}</p>
              <p>{t("inquiriesSection1Paragraph2")}</p>
              <p>{t("inquiriesSection1Paragraph3")}</p>
              <ul>
                <li>{t("inquiriesSection1List1")}</li>
                <li>{t("inquiriesSection1List2")}</li>
                <li>{t("inquiriesSection1List3")}</li>
                <li>{t("inquiriesSection1List4")}</li>
              </ul>
              <p>{t("inquiriesSection1Paragraph4")}</p>
              <h4>{t("inquiriesSection2")}</h4>
              <p>{t("inquiriesSection2Paragraph1")}</p>
              <p>{t("inquiriesSection2Paragraph2")}</p>
              <ul>
                <li>{t("inquiriesSection2List1")}</li>
                <li>{t("inquiriesSection2List2")}</li>
                <li>{t("inquiriesSection2List3")}</li>
                <li>{t("inquiriesSection2List4")}</li>
              </ul>
              <h4>{t("inquiriesSection3")}</h4>
              <p>{t("inquiriesSection3Paragraph1")}</p>
              <p>{t("inquiriesSection3Paragraph2")}</p>
              <h4>{t("inquiriesSection4")}</h4>
              <p>{t("inquiriesSection4Paragraph1")}</p>
              <p>{t("inquiriesSection4Paragraph2")}</p>
              <ul>
                <li>{t("inquiriesSection4List1")}</li>
                <li>{t("inquiriesSection4List2")}</li>
                <li>{t("inquiriesSection4List3")}</li>
              </ul>
              <p>{t("inquiriesSection4Paragraph3")}</p>
              <h4>{t("inquiriesSection5")}</h4>
              <p>{t("inquiriesSection5Paragraph1")}</p>
              <ul>
                <li>{t("inquiriesSection5List1")}</li>
                <li>{t("inquiriesSection5List2")}</li>
                <li>{t("inquiriesSection5List3")}</li>
              </ul>
              <h4>{t("inquiriesSection6")}</h4>
              <p>{t("inquiriesSection6Paragraph1")}</p>
              <p>
                {t("addressLine1")}
                <br />
                {t("addressLine2")}
                <br />
                {t("addressLine3")}
                <br />
                {t("addressLine4")}
                <br />
                {t("addressLine5")}
              </p>
            </div>
            <p>
              <a href="mailto:privacy@cubic.ca">privacy@cubic.ca</a>
            </p>
          </div>
        </div>
      </main>
      <Footer displayDownloadBtn={false} />
    </div>
  );
};

export default PrivacyPage;
